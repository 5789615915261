/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import r from "../core/Accessor.js";
import e from "../core/Error.js";
import o from "../core/Logger.js";
import { isAbsolute as s, makeRelative as a } from "../core/urlUtils.js";
import { property as l } from "../core/accessorSupport/decorators/property.js";
import { cast as p } from "../core/accessorSupport/decorators/cast.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
let c = class extends r {
  constructor(t) {
    super(t), this.portalItem = null;
  }
  normalizeCtorArgs(t) {
    return t?.portalItem && t.path ? {
      ...t,
      path: n(t.path, t.portalItem)
    } : t;
  }
  set path(t) {
    null != t && s(t) ? o.getLogger(this).error("portalitemresource:invalid-path", "A portal item resource path must be relative") : this._set("path", t);
  }
  _castPath(t) {
    return n(t, this.portalItem);
  }
  get url() {
    return this.portalItem && this.path ? `${this.portalItem.itemUrl}/resources/${this.path}` : null;
  }
  get cdnUrl() {
    return this.portalItem && this.path ? `${this.portalItem.itemCdnUrl}/resources/${this.path}` : null;
  }
  get itemRelativeUrl() {
    return this.portalItem && this.path ? `./resources/${this.path}` : null;
  }
  fetch(t = "json", r) {
    const o = this.cdnUrl;
    if (null == o) throw new e("portal-item-resource:fetch", "Portal item resource does not refer to a valid item or path");
    return this.portalItem.portal.request(o, {
      responseType: t,
      query: {
        token: this.portalItem.apiKey,
        _ts: r?.cacheBust ? Date.now() : null
      },
      signal: r?.signal
    });
  }
  async update(t, r) {
    const {
      addOrUpdateResources: e
    } = await import("./support/resourceUtils.js");
    return await e(this.portalItem, [{
      resource: this,
      content: t,
      compress: r?.compress,
      access: r?.access
    }], "update", r), this;
  }
  hasPath() {
    return null != this.path;
  }
};
function n(t, r) {
  return null == t ? t : (t = t.replace(/^\/+/, ""), null != r && s(t) && (t = a(t, r.itemUrl)), t?.replace(/^\/+/, "").replace(/^(\.\/)?resources\//, ""));
}
t([l()], c.prototype, "portalItem", void 0), t([l({
  type: String,
  value: null
})], c.prototype, "path", null), t([p("path")], c.prototype, "_castPath", null), t([l({
  type: String,
  readOnly: !0
})], c.prototype, "url", null), t([l({
  type: String,
  readOnly: !0
})], c.prototype, "cdnUrl", null), t([l({
  type: String,
  readOnly: !0
})], c.prototype, "itemRelativeUrl", null), c = t([i("esri.portal.PortalItemResource")], c);
const u = c;
export { u as default };